.winafe__navbar{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0rem 1rem;
}

.winafe__navbar-links{
    flex: 1;
    display: flex;
    justify-content:  flex-start;
    align-items: center;
}

.winafe__navbar-links_logo{
    margin: 2 rem;
}

.winafe__navbar-links_logo img{
    margin-top: 10px;
    width: 113px;
    height: 48px;
}

.winafe__navbar-links_container{
    display: flex;
    flex-direction: row;
    
}

.winafe__navbar-links_container p,
.winafe__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 42px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.winafe__navbar-menu{
    margin-left: 1rem;

    display: none;
    position: relative;
}

.winafe__navbar-menu svg{
    cursor: pointer;
}
.winafe__navbar-menu_container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolut;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0,0,0,0.2);
}
.winafe__navbar-links_container p{
    
    margin-left: 50%;
}

@media screen and (max-width: 700px) {
    .winafe__navbar-links_container{
        display: none;
    }
    .winafe__navbar-links_logo{
        display: none;
    }
    .winafe__navbar-menu{
        display: none;
    }
    
}
/*
@media screen and (max-width: 800px) {
    .winafe__navbar-links_logo{
        display: none;
    }
    .winafe__navbar{
        padding: 1rem 1rem;
    }
    
}*/
