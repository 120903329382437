.Slider{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -10%;
    margin-bottom: 3%;
}
  
.card_imgBox{
  display: flex;
  width: 70vw;
  height: 50vw;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 700px) {
  .Slider{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -40%;
    margin-bottom: 3%;
}
  .card_imgBox{
    width: 100vw;
    height: 100vw;
    justify-content: center;
    align-items: center;
    margin-left: 0%;

  }
}

  