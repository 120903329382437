.winafe__info_container{
    position: relative;
    width: 800px;
    justify-content: center;
    align-items: center;
    margin: 20% auto;
  }

.switcher{
    position: absolute;
    top: 6%; /* Adjust the top position as needed */
    right: 100px; /* Adjust the right position as needed */
    z-index: 1; /* Ensure it's above other elements */
}

@media screen and (max-width: 1000px) {

    .winafe__info_container{
        position: relative;
        width: auto;
        margin: 0 auto;
        
    }
    .switcher{
        position: absolute;
        top: 67%; /* Adjust the top position as needed */
        right: 47%; /* Adjust the right position as needed */
        z-index: 1; /* Ensure it's above other elements */
    }
}