*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    
}

body{
  width: auto;
  height: auto;
  background: url("assets/img/winafe__bg.png");
 
  background-size: 100%;
    
}
section {
  height: 100vh; /* Set each section to the height of the viewport */
}

a {
    color: unset;
    text-decoration: none;
}

/*
.winafe__title_container{
  display: flex;
  width: auto;
  height: auto;
  justify-content: center;
  margin: 10px auto;
  color: #FFF;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  text-shadow: 0px 10px 30px #6DDCFF;
  font-family: Rowdies, Arial, Helvetica, sans-serif;
  font-size: 128px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; 166.4px 
}
*/
.winafe__title_container {
  display: flex;
  background: url("assets/logo__winafe__white.png");
  justify-content: center;
  flex-direction: column;
  width: 641px;
  height: 272px; 
  background-size: cover;
  margin: 50px auto;
  margin-top: 10%;
  margin-bottom: 50px;
}
.winafe__subtitle_container{
  display: flex;
  width: auto;
  height: 75px;
  flex-direction: column;
  justify-content: center;
  margin: 20px auto;
  margin-top: -50px;
  flex-shrink: 0;
  color: #6DDCFF;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  text-shadow: 0px 10px 30px #6DDCFF;
  font-family: Rowdies, Arial, Helvetica, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: 130%; /* 46.8px */
}

.img__bg{
  width: 100%;
  height: 130%;
 
  left: 27%;
  position: fixed;
  background: url("assets/img/deer_white.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  margin-top: -10%;
  z-index: -1;
}

.img__bg-left{
  width: 30%;
  height: 100%;
  position: fixed;
  left: 0;

  background: url("assets/party_dude_1.png");
  background-repeat: no-repeat;
  background-size: cover;
  
}
.img__bg-right{
  width: 32%;
  height: 100%;
  
  position: fixed;
  right: 0;

  background: url("assets/santa_modern_1.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.gradient__bg {
    background:-moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  
    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 0.5) 0%, rgba(4, 12, 24, 1) 25%);
  
    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 0.5) 0%, rgba(4, 12, 24, 1) 25%);
  
    /* ie 10+ */
    background:-ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 0.5) 0%, rgba(4, 12, 24, 1) 25%);
  
    /* global 92%+ browsers support */
    background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 0.5) 0%, rgba(4, 12, 24, 1) 25%);
  }

  .gradient__text{
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .section__padding{
    padding: 4rem 6rem;
  }

  .section__margin{
    margin: 4rem 6rem;
  }

  .scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}




@-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @media screen and (max-width: 700px){
    .section__padding{
        padding: 4rem 6rem;
    }
    .section__margin{
        margin: 4rem;
    }
  }
  @media screen and (max-width: 550px){
    .section__padding{
        padding: 4rem 2rem;
    }
    .section__margin{
        margin: 4rem 2rem;
    }
  }
.snow, .snow2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('assets/img/snow.png');
  z-index: -1;
  
}

.snow {
  background-size: 70em;
  animation: snowfall 30s linear infinite;
}
.snow2{
  background-size: 50em;
  animation: snowfall2 30s linear infinite;
  opacity: 0.5;
}

@keyframes snowfall {
  100%{
    background-position: 50em 70em;
  }
}

@keyframes snowfall2 {
  100%{
    background-position: 20em 70em;
  }
}

.blinking_btn {
  display: flex;
  width: 22%;
  align-items: center; /* Align vertically in the center */
  justify-content: center; /* Align horizontally to the right */
  margin-top: 8%;
  margin-bottom: -12%;
  margin-left: 70%;
  background-color: #1c87c9;
  -webkit-border-radius: 60px;
  border-radius: 60px;
  border: none;
  color: #eeeeee;
  cursor: pointer;
  font-family: sans-serif;
  font-size: 48px;
  padding: 5px 5px;
  text-align: center;
  text-decoration: none;
  font-family: var(--font-family);
  font-weight: 400;
  transform: rotate(10deg); /* Use transform property for rotation */
}
@keyframes glowing {
  0% {
    background-color: #a80505;
    box-shadow: 0 0 3px #a80505;
  }
  50% {
    background-color: #e81919;
    box-shadow: 0 0 10px #e81919;
  }
  100% {
    background-color: #a80505;
    box-shadow: 0 0 3px #a80505;
  }
}
.blinking_btn{
  animation: glowing 1300ms infinite;
}

.winafe__btn-landingpage-container {
  width: 100%;
  height: 263.045px;
  justify-content: center;
  margin: 0px auto;

 
}

.winafe__btn-landingpage-link{
  display: flex;
  width: 100%;
  height: 263.045px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  
  font-family: 'Rowdies', sans-serif;
  font-size: 72px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 62.4px */
}


.winafe__info_title {
  display: flex;
  width: 645px;
  height: 117px;
  flex-direction: column;
  justify-content: center; /* Adjust this based on your vertical alignment preference */
  align-items: center; /* Center horizontally */
  margin: 0 auto;
  flex-shrink: 0;

  color: #000;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Rowdies;
  font-size: 96px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 124.8px */
}
.winafe__info_text{
  display: flex;
  width: 824px;
  height: 774px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;


  color: #000;
  text-align: center;
  margin: 3% 30px;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Rowdies;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 36.4px */
  z-index: +1;
}


@media screen and (max-width: 1400px) {
  .winafe__title_container {
    display: flex;
    position: relative;
    width: 100%;
    background: url("assets/logo__winafe__white.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .winafe__subtitle_container {
    font-size: 32px;
  }

  .winafe__btn-landingpage-link {
    font-size: 48px;
  }

  
 
  
  .blinking_btn{
    font-size: 24px;
    width: 40%;
    transform: rotate(0deg);
    justify-content: center;
    align-items: center;
    margin-left: 30%;
    margin-top: 0%;  
  }
}

@media screen and (max-width: 1000px) {

  body {
    display: flex;
    height: 1000px;
    background: url("assets/img/winafe__bg.png");
    background-size: 200%;  /*Use cover to fill the entire viewport */
    background-repeat: no-repeat;
    justify-content: center;
  }
  
  .winafe__title_container {
    display: flex;
    position: relative;
    width: 90%;
    height: 180px;
    background: url("assets/logo__winafe__white.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
  .winafe__subtitle_container{
    display: flex;
    width: auto;
   
    flex-shrink: 0;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    text-shadow: 0px 10px 30px #6DDCFF;
    font-family: Rowdies, Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    margin-top: -23%;
    
  }
  .winafe__btn-landingpage-container {
    width: 100%;
    justify-content: center;
    margin: -30% auto;
  }

  .winafe__btn-landingpage-link {
    font-size: 24px;
  }
  .blinking_btn{
    font-size: 16px;
    width: 40%;
    transform: rotate(0deg);
    justify-content: center;
    align-items: center;
    margin-left: 30%;
    margin-top: 15%;  
  }
  .winafe__info_container{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: -40%;
    margin-left: 1.4%;
  }

  .winafe__info_title {
    font-size: 62px;
  }

  .winafe__info_text {
    font-size: 32px;
  }
  .SlideContainer{
    display: flex;
  }
}




